import React, { ReactNode } from 'react';

import { Row } from 'components/row/Row';

import s from './BlockHeading.scss';

interface BlockHeadingProps {
  children: ReactNode;
}

export const BlockHeading = ({ children }: BlockHeadingProps) => (
  <>
    <div className={s.block}>
      <Row>
        <div className={s.block__col}>
          <h3 className={s.block__heading}>{children}</h3>
        </div>
      </Row>
    </div>
  </>
);
