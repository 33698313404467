import React, { useEffect, useRef, useState } from 'react';
import s from './collections.scss';
import Img from 'gatsby-image';
import { TimelineLite, Power3, Power2, gsap } from 'gsap';


interface ChildImageSharpObj {
  fluid: any
}

interface FeaturedObj {
  childImageSharp: ChildImageSharpObj
}

interface FrontmatterObj {
  title: string,
  path: string,
  featured: FeaturedObj
}

interface MarkdownRemark {
  frontmatter: FrontmatterObj
}

interface CollectionsProps {
  selectCollection: any,
  selectedFurniture: any,
  selectedCollection: string,
  collections: any,
  setCollectionAddress: any
}

export const CollectionsForm = ({ selectCollection, selectedFurniture, selectedCollection, collections, setCollectionAddress }: CollectionsProps) => {
  const [collection, setCollection] = useState<string>('');

  const border = useRef([]);
  const image = useRef([]);

  const imageRefs = el => {
    if (el && !image.current.includes(el)) {
      image.current.push(el);
    }
  };

  const borderRefs = el => {
    if (el && !border.current.includes(el)) {
      border.current.push(el);
    }
  };
  
  const isVisible = (name: string) => {
    return name === collection;
  };

  const animateVisible = (imageIndex) => {
    const tl = new TimelineLite();

    tl.fromTo(border.current[imageIndex], .5, {
      delay: 0,
      scaleX: 0,
      transformOrigin: 'left',
      ease: Power3.easeOut,
    }, {
      scaleX: 1,
      opacity: 1,
    });
    tl.to(image.current[imageIndex], .5, {
      delay: 0,
      opacity: 1,
    });
  };

  const animateInvisible = (imageIndex) => {
    const tl = new TimelineLite();

    tl.to(image.current[imageIndex], 1, {
      delay: 0,
      opacity: 0,
    });
    tl.to(border.current[imageIndex], 1, {
      scaleX: 0,
      transformOrigin: 'left',
      ease: Power2.easeOut,
    });
  };

  const changeCollection = (name: string) => {
    setCollection(name);
  };

  // @ts-ignore
  return (
    <div className={s(s.collections, s.collections__dropdownMenu)}>
      <ul className={s.collections__list}>
        {collections.map((allMarkdownRemark: MarkdownRemark, i) =>
          <li
            key={allMarkdownRemark.frontmatter.title}
            className={s.collections__name}
            onClick={() => {
              selectCollection(allMarkdownRemark.frontmatter.title);
              setCollectionAddress(`https://intobello.com${allMarkdownRemark.frontmatter.path}`);
              selectedFurniture('');
            }}
            onMouseEnter={() => {
              changeCollection(allMarkdownRemark.frontmatter.title);
              animateVisible(i);
            }}
            onMouseLeave={() => {
              changeCollection('');
              animateInvisible(i);
            }}

          >
            <div className={s.collections__preview}>
                  <span
                    className={s(s.collections__textBlock, { active: isVisible(allMarkdownRemark.frontmatter.title) || selectedCollection === allMarkdownRemark.frontmatter.title })}>
                    {allMarkdownRemark.frontmatter.title}
                  </span>
              <div ref={borderRefs}
                   className={s(s.collections__lineBlock)}>
                <hr className={s.collections__line}/>
              </div>
              <div ref={imageRefs}
                   className={s(s.collections__imageBlock)}>
                <Img fluid={allMarkdownRemark.frontmatter.featured.childImageSharp.fluid}/>
              </div>
            </div>
          </li>,
        )}
      </ul>
    </div>
  );
};
