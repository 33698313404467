import React from 'react';
import s from './formWrapper.scss';

interface FormWrapperProps {
  child: any
}

export const FormWrapper = ({ child } : FormWrapperProps) => {
  
  return (
    <div className={s(s.formWrapper)}>
      {child}
    </div>
  );
};
