import React, { useRef, useState } from 'react';

import s from './furnitures.scss';
import Img from 'gatsby-image';
import { Power2, Power3, TimelineLite } from 'gsap';

interface ChildImageSharpObj {
  fluid: any
}

interface FeaturedObj {
  childImageSharp: ChildImageSharpObj
}

interface FurnitureObj {
  name: string,
  image: FeaturedObj
}

interface FrontmatterObj {
  collection: FurnitureObj[],
}

interface MarkdownRemark {
  frontmatter: FrontmatterObj
}

interface CollectionsProps {
  displaySelectedFurinitures: any,
  selectedFurnitureText: string,
  furnitures: MarkdownRemark
}

export const FurnituresForm = ({ displaySelectedFurinitures, selectedFurnitureText, furnitures }: CollectionsProps) => {
  const [furniture, setFurniture] = useState<string>();

  const isVisible = (name: string) => name === furniture;

  const isFurnitureSelect = (furnitureName: string) => {
    const test = selectedFurnitureText && selectedFurnitureText.includes(furnitureName);

    return test;
  };

  const changeFurniture = (name: string) => {
    setFurniture(name);
  };

  const border = useRef([]);
  const image = useRef([]);

  const imageRefs = el => {
    if (el && !image.current.includes(el)) {
      image.current.push(el);
    }
  };

  const borderRefs = el => {
    if (el && !border.current.includes(el)) {
      border.current.push(el);
    }
  };

  const animateVisible = (imageIndex) => {
    const tl = new TimelineLite();

    tl.fromTo(border.current[imageIndex], .5, {
      delay: 0,
      scaleX: 0,
      transformOrigin: 'left',
      ease: Power3.easeOut,
    }, {
      scaleX: 1,
      opacity: 1,
    });
    tl.to(image.current[imageIndex], .5, {
      delay: 0,
      opacity: 1,
    });
  };

  const animateInvisible = (imageIndex) => {
    const tl = new TimelineLite();

    tl.to(image.current[imageIndex], 1, {
      delay: 0,
      opacity: 0,
    });
    tl.to(border.current[imageIndex], 1, {
      scaleX: 0,
      transformOrigin: 'left',
      ease: Power2.easeOut,
    });
  };

  return (
    <div className={s(s.furnitures, s.furnitures__dropdownMenu)}>
      <ul className={s.furnitures__list}>
        {
          furnitures && furnitures.frontmatter.collection.map((thisFurniture, i) =>
            <li
              key={thisFurniture.name}
              className={s.furnitures__name}
              onClick={() => {
                displaySelectedFurinitures(thisFurniture.name);
              }}
              onMouseEnter={() => {
                changeFurniture(thisFurniture.name);
                animateVisible(i);
              }}
              onMouseLeave={() => {
                changeFurniture('');
                animateInvisible(i);
              }}

            >
              <div className={s.furnitures__preview}>
                  <span className={s(s.furnitures__textBlock, { active: isVisible(thisFurniture.name) })}>
                    <div
                      className={isFurnitureSelect(thisFurniture.name) ? s.furnitures__selected : s.furnitures__disabled}>
                    </div>
                    {thisFurniture.name}
                  </span>
                <div ref={borderRefs}
                     className={s.furnitures__lineBlock}>
                  <hr/>
                </div>
                <div ref={imageRefs} className={s.furnitures__imageBlock}>
                  <Img fluid={thisFurniture.image.childImageSharp.fluid}/>
                </div>
              </div>
            </li>,
          )
        }
      </ul>
    </div>
  );
};
