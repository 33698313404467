import React, { useEffect, useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { useIntl } from 'gatsby-plugin-intl';

import { Button } from '../button/Button';
import { CollectionsForm } from './collections/collections';
import { FurnituresForm } from './furnitures/furnitures';
import { graphql, StaticQuery } from 'gatsby';
import { BlockHeading } from '../block-heading/BlockHeading';
import { AnimationWrapper } from '../animationWrapper/AnimationWrapper';
import { useAppDispatchContext, useAppStateContext } from '../../context/appContext';

import s from './Form.scss';

const questionType = [
  {
    name: 'collectionQuestion',
    value: 'collection',
  },
  {
    name: 'generalQuestion',
    value: 'general',
  },
];

interface SelectedFurnitureObj {
  name: string,
}

interface CollectionsProps {
  topText: string,
  secondText: string,
  collectionName: string | undefined,
  collectionQuestion: boolean | undefined,
  lang: string
}

export const Form = ({ topText, collectionName, collectionQuestion, secondText, lang }: CollectionsProps) => {
  const intl = useIntl();

  const [displayDropdownMenu, setDisplay] = useState(false);
  const [sendCopyMessageSelected, setSendCopyMessageSelect] = useState(false);
  const [policySelected, setPolicySelect] = useState(false);
  const [selectedQuestionType, selectQuestionType] = useState(collectionQuestion ? intl.formatMessage({ id: questionType[0].name }) : intl.formatMessage({ id: questionType[1].name }));
  const [selectedCollection, selectCollection] = useState(collectionName || '');
  const [selectedFurniture, selectFurniture] = useState('');
  const [collectionImg, setCollectionImg] = useState('');
  const [selectedCollectionAddress, setCollectionAddress] = useState('');
  const [responseToken, setResponseToken] = useState('');
  const [trySendEmail, setTrySendEmail] = useState(false);

  let recaptchaInstance;

  const dispatch = useAppDispatchContext();

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  useEffect(() => {
    if (trySendEmail && responseToken !== '') {
      prepareEmailData();
    }
  }, [trySendEmail, responseToken]);

  const createArray = () => {
    const furnitureArray: SelectedFurnitureObj[] = [];

    if (selectedFurniture === '') {
      return furnitureArray;
    }

    const words = selectedFurniture.split(', ');
    words.pop();

    words.map(word => {
      const newFurnitureObject = {
        name: word,
      };

      furnitureArray.push(newFurnitureObject);

    });

    return furnitureArray;
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setTrySendEmail(true);
    event.preventDefault();

    if (responseToken === '' && typeof window !== 'undefined' && typeof document !== 'undefined') {
      let recaptcha = document.getElementById('g-recaptcha');

      recaptcha.style.width = '306px';
      recaptcha.style.height = '80px';
      recaptcha.style.border = '2px solid red';

      return;
    }
  };

  const sendEmail = async (content: string) => {
    // const myUrl = 'https://noher3ikt3.execute-api.eu-west-1.amazonaws.com/dev3/email/send';
    const myUrl = 'https://9cpklb55f0.execute-api.eu-west-1.amazonaws.com/dev2/email/send';

    await fetch(myUrl, {
      method: 'POST',
      body: content,
      headers: { 'Content-Type': 'txt/html' },
    }).then((response) => {
      if (!response.ok) {
        if (typeof document !== 'undefined') {
          const fail = document.getElementById('form-fail');
          fail && fail.click();
        }
      }

// If you care about a response
      if (response.status.toString().startsWith('2')) {
        if (typeof document !== 'undefined') {
          const success = document.getElementById('form-success');
          success && success.click();
        }
      }
    });

  };

  const prepareFurnitureData = (data: any) => {
    const foundCollection = data.allMarkdownRemark.nodes.find((node: any) => node.frontmatter.title === selectedCollection);

    if (collectionImg === '' && foundCollection) {
      setCollectionImg(`https://intobello.com/collectionFeatured${foundCollection.frontmatter.path}.png`);
      setCollectionAddress(`https://intobello.com${foundCollection.frontmatter.path}`);
    }

    return foundCollection;
  };

  const displaySelectedFurinitures = (newSelectedFurniture: string) => {
    const nodisplay = selectedFurniture.includes(newSelectedFurniture);

    let selectedList = selectedFurniture;

    if (nodisplay) {
      selectedList = selectedFurniture.replace(newSelectedFurniture + ', ', '');
    } else {
      selectedList = selectedFurniture + newSelectedFurniture + ', ';
    }

    selectFurniture(selectedList);
  };

  const prepareEmailData = () => {
    let form = [];
    let data = [];

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      form = document.querySelector('form')!;
      data = new FormData(form);
    }

    const payload = {
      name: data.get('name') as string,
      email: data.get('email') as string,
      question: data.get('question') as string,
    };

    const furniture = createArray();

    let emailData = {
      name: payload.name,
      email: payload.email,
      question: payload.question,
      subjectText: lang === 'en'
        ? `${payload.name}, thank you for reaching us!`
        : `${payload.name}, merci de nous avoir rejoint!`,
      responseToken,
      language: lang,
    };

    if (selectedQuestionType === intl.formatMessage({ id: questionType[0].name })) {


      emailData = {
        ...emailData,
        // @ts-ignore
        collectionName: selectedCollection,
        collectionImage: collectionImg,
        collectionAddress: selectedCollectionAddress,
        allFurniture: furniture,
        subjectText: lang === 'en'
          ? `${payload.name}, you've made an excellent choice!`
          : `${payload.name}, vous avez fait un excellent choix!`,
      };
    }

    const content = JSON.stringify({ ...emailData });
    sendEmail(content);
  };

  const verifyCallback = (responseToken) => {
    setResponseToken(responseToken);

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      let recaptcha = document.getElementById('g-recaptcha');

      recaptcha.style.width = '306px';
      recaptcha.style.height = '80px';
      recaptcha.style.border = '2px solid white';
    }
  };

  function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
  }

  const runRecaptchaAnimation = () => {
    let recaptcha = document.getElementById('g-recaptcha');

    if (!hasClass(recaptcha, 'motion-recaptcha')) {
      recaptcha.classList.add('motion-recaptcha');
    }
  };

  return (
    <>
      <div className={s.form__blockText}>
        <BlockHeading>{topText}</BlockHeading>
        <BlockHeading>{secondText}</BlockHeading>
      </div>
      <StaticQuery
        query={graphql`
      query CollectionsQueryForm {
        allMarkdownRemark(filter: { frontmatter: { language: { eq: "en" } } }, sort: { order: ASC, fields: [frontmatter___id] }) {
        nodes {
          frontmatter {
            path
            title
            featured {
              childImageSharp {
                fluid(maxWidth: 160) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            collection {
              name
              image {
                childImageSharp {
                  fluid(maxWidth: 160) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    `}
        render={(data) => (
          <form className={s.form}
                onSubmit={handleFormSubmit}
                // action={'https://noher3ikt3.execute-api.eu-west-1.amazonaws.com/dev3/email/send'}
            action={'https://9cpklb55f0.execute-api.eu-west-1.amazonaws.com/dev2/email/send'}

                method={'post'}
                id={'contactForm'}
          >

            <div className={s.form__field}>
              <input onChange={() => runRecaptchaAnimation()} placeholder={intl.formatMessage({ id: 'fullName' })}
                     className={s.form__formInput} type="text"
                     name="name" required />
            </div>

            <div className={s.form__field}>
              <input placeholder={intl.formatMessage({ id: 'yourEmail' })} className={s.form__formInput} type="email"
                     name="email" required />
            </div>

            <div className={s(s.form__field, s.form__customSelect, { hidden: collectionQuestion })}>
              <p data-name="question" className={s.form__questionType}
                 onClick={() => setDisplay(!displayDropdownMenu)}>{selectedQuestionType}</p>
              <div className={displayDropdownMenu ? s(s.form__arrow__isActive, s.form__arrow) : s.form__arrow} />
              <div className={displayDropdownMenu ? s.form__dropdownMenu : s.form__dropdownMenu__hidden}>
                <div className={s.form__questionTypes} onClick={() => {
                  selectQuestionType(intl.formatMessage({ id: questionType[0].name }));
                  setDisplay(false);
                }}>
                  {intl.formatMessage({ id: questionType[0].name })}
                </div>
                <div className={s.form__questionTypes} onClick={() => {
                  selectQuestionType(intl.formatMessage({ id: questionType[1].name }));
                  setDisplay(false);
                }}>{intl.formatMessage({ id: questionType[1].name })}</div>
              </div>
            </div>

            <div
              className={s(s.form__collectionQuestion, { hidden: selectedQuestionType === intl.formatMessage({ id: questionType[1].name }) })}>

              <div className={s(s.form__collectionPlaceholder, { hidden: collectionQuestion })}>
                <div className={s(s.form__field, s.form__collection)}>
                  <input placeholder={intl.formatMessage({ id: 'sellectCollection' })} className={s.form__formInput}
                         type="text" name="name"
                         required={selectedQuestionType === intl.formatMessage({ id: questionType[0].name })}
                         value={selectedCollection}
                         onChange={() => {
                           '';
                         }} onKeyDown={() => {
                    return false;
                  }} />
                </div>

                <CollectionsForm
                  selectCollection={selectCollection}
                  selectedFurniture={selectFurniture}
                  selectedCollection={selectedCollection}
                  collections={data.allMarkdownRemark.nodes}
                  setCollectionAddress={setCollectionAddress}
                />
              </div>

              <div className={s(s.form__furniturePlaceholder, { hidden: selectedCollection === '' })}>
                <div className={s(s.form__field, s.form__furniture, { active: selectedFurniture !== '' })}>
                  <textarea placeholder={intl.formatMessage({ id: 'sellectFurnitues' })} className={s.form__formInput}
                            name="name"
                            required={selectedQuestionType === intl.formatMessage({ id: questionType[0].name })}
                            value={selectedFurniture}
                            onChange={() => {
                              '';
                            }} onKeyDown={() => {
                    return false;
                  }} />
                </div>

                <FurnituresForm
                  displaySelectedFurinitures={displaySelectedFurinitures}
                  selectedFurnitureText={selectedFurniture}
                  furnitures={prepareFurnitureData(data)}
                />
              </div>

            </div>

            <div className={s(s.form__field, s.form__message)}>
              <textarea placeholder={intl.formatMessage({ id: 'message' })} name="question" wrap="off" required />
            </div>

            <div className={s.form__checkbox} onClick={() => setSendCopyMessageSelect(!sendCopyMessageSelected)}>
              <input type="checkbox" name="copyCheckbox"
                     className={s(s.form__disabled, { selected: sendCopyMessageSelected })}
                     checked={sendCopyMessageSelected} onChange={() => {
                '';
              }} required />
              <label className={s.form__checkboxLabel}>{intl.formatMessage({ id: 'checkboxFirst' })}</label>
            </div>

            <div className={s.form__checkbox} onClick={() => setPolicySelect(!policySelected)}>
              <input type="checkbox" name="policyCheckbox" className={s(s.form__disabled, { selected: policySelected })}
                     checked={policySelected} onChange={() => {
                '';
              }} required />
              <label className={s.form__checkboxLabel}> {intl.formatMessage({ id: 'checkboxSecondPart1' })}
                <a className={s.form__checkboxLink} onMouseOver={() => onCursor('action')}
                   onMouseLeave={() => onCursor()}
                   href={'https://intobello.com/PersonalDataProtectionPolicy.pdf'}
                   target={'_blank'}>
                  {intl.formatMessage({ id: 'privacyPolicy' })}
                </a>
                {intl.formatMessage({ id: 'and' })}
                <a
                  onMouseOver={() => onCursor('action')}
                  onMouseLeave={() => onCursor()}
                  className={s.form__checkboxLink} href={'https://intobello.com/GDPR.pdf'}
                  target={'_blank'}>
                  {intl.formatMessage({ id: 'generalDataProtectionRegulation' })}
                </a>
                {intl.formatMessage({ id: 'checkboxSecondPart2' })}
              </label>
            </div>

            <div className={s.form__checkbox} onClick={() => setPolicySelect(!policySelected)}>
              <label className={s.form__checkboxLabel}>
                {intl.formatMessage({ id: 'recaptcha' })}
                <a onMouseOver={() => onCursor('action')}
                   onMouseLeave={() => onCursor()} className={s.form__checkboxLink}
                   href={'https://policies.google.com/privacy'}
                   target={'_blank'}>{intl.formatMessage({ id: 'privacyPolicy' })}</a>
                {intl.formatMessage({ id: 'and' })}
                <a onMouseOver={() => onCursor('action')}
                   onMouseLeave={() => onCursor()} className={s.form__checkboxLink}
                   href={'https://policies.google.com/terms'}
                   target={'_blank'}>
                  {intl.formatMessage({ id: 'termsOfService' })}
                </a>
                {intl.formatMessage({ id: 'apply' })}
              </label>
            </div>
            <Recaptcha
              ref={e => recaptchaInstance = e}
              sitekey="6LexMbcZAAAAAP5--wawgZ0ORlrBPJs9fgcLBnku"
              render="explicit"
              size="normal"
              verifyCallback={response => verifyCallback(response)}
            />
            <div className={s.form__button}>
              <Button type="submit" color={'accent'}>{intl.formatMessage({ id: 'sendMessageButton' })}</Button>
            </div>
            <div className={s.form__hiddenButton}>
              <AnimationWrapper
                path={'/thank-you'}
              >
                <div id={'form-success'}>Success</div>
              </AnimationWrapper>
            </div>
            <div className={s.form__hiddenButton}>
              <AnimationWrapper
                path={'/try-another-way'}
              >
                <div id={'form-fail'}>Fail</div>
              </AnimationWrapper>
            </div>
          </form>
        )}
      />
    </>
  );
};
